<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="productTitle"
                    label="商品标题"
                >
                    <el-input
                        v-model="queryFormModel.productTitle"
                        placeholder="请输入商品标题"
                    />
                </el-form-item>
                <el-form-item
                    prop="productBrand"
                    label="商品品牌"
                >
                    <el-input
                        v-model="queryFormModel.productBrand"
                        placeholder="请输入商品品牌"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="productTitle"
                    label="商品标题"
                    min-width="100"
                />
                <el-table-column
                    prop="productBrand"
                    label="商品品牌"
                    min-width="100"
                />
                <el-table-column
                    prop="productCode"
                    label="商品条码"
                    min-width="100"
                />
                <el-table-column
                    prop="isAvailable"
                    label="是否可用"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isAvailable ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="修改时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row,1)"
                        >
                            编辑
                        </el-button>
                        <!--<el-button
type="text"
size="small"
@click="$router.push('/ts/marketCode/marketCodeProductDetail/'+ scope.row.id)"
>
产品溯源
</el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row,2)"
                        >
                            复制产品
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="productTitle"
                    label="商品标题"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.productTitle"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="productBrand"
                    label="商品品牌"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.productBrand"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="productCode"
                    label="商品条码"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.productCode"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="productImage"
                    label="商品图片"
                    label-width="6em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="10"
                        v-model="addDialog.formModel.productImage"
                    />
                </el-form-item>
                <el-form-item
                    prop="productAd"
                    label="商品广告"
                    label-width="6em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="10"
                        v-model="addDialog.formModel.productAd"
                        accept=".mp4,.jpg,.jpeg,.png"
                    />
                </el-form-item>
                <el-form-item
                    prop="productDescList"
                    label="商品规格"
                    label-width="6em"
                >
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAddItem"
                    >
                        新增规格
                    </el-button>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="addDialog.formModel.productDescList"
                        style="width: 100%"
                        class="ma-t"
                    >
                        <el-table-column
                            prop="specName"
                            label="规格名称"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input
                                    v-model="scope.row.specName"
                                    auto-complete="off"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="specValue"
                            label="规格值"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <el-input
                                    v-model="scope.row.specValue"
                                    auto-complete="off"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="120"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onDeleteItem(scope.row)"
                                >
                                    删除
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onUpItem(scope.row, scope.$index)"
                                    v-if="scope.$index !== 0"
                                >
                                    上移
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onDownItem(scope.row, scope.$index)"
                                    v-if="(addDialog.formModel.productDescList.length - 1) !== scope.$index"
                                >
                                    下移
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                    label-width="6em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isAvailable"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'MarketCodeProduct',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                productTitle: '', // 商品标题
                productBrand: '', // 商品品牌
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    productTitle: '',
                    productBrand: '',
                    productCode: '',
                    productImage: [],
                    productDescList: [],
                    productAd: [],
                    isAvailable: true,
                },
                // 表单校验规则
                formRules: {
                    productTitle: {
                        required: true,
                        message: '请输入商品标题',
                        trigger: 'blur',
                    },
                    productBrand: {
                        required: true,
                        message: '请输入商品品牌',
                        trigger: 'blur',
                    },
                    productCode: {
                        required: true,
                        message: '请输入商品条码',
                        trigger: 'blur',
                    },
                    productImage: {
                        type: 'array',
                        required: true,
                        message: '请上传商品图片',
                        trigger: 'blur',
                    },
                    productDescList: {
                        type: 'array',
                        required: true,
                        message: '请选择商品规格',
                        trigger: 'blur',
                    },
                },
                type: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ts.MarketCodeProduct.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableEdit(row, type) {
            const { formModel } = this.addDialog;
            this.$api.Ts.MarketCodeProduct.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
                this.addDialog.formModel.productDescList = JSON.parse(res.productDesc);
            });
            this.addDialog.type = type;
            this.addDialog.isVisible = true;
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ts.MarketCodeProduct.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Ts.MarketCodeProduct.save({
                    ...this.addDialog.formModel,
                    productDesc: JSON.stringify(this.addDialog.formModel.productDescList),
                    isCopy: this.addDialog.type === 2,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onAddItem() {
            this.addDialog.formModel.productDescList.push({
                specName: '',
                specValue: '',
            });
        },
        onDeleteItem(row) {
            this.addDialog.formModel.productDescList.splice(this.addDialog.formModel.productDescList.indexOf(row), 1);
        },
        onUpItem(row, index) {
            if (index !== 0) {
                // eslint-disable-next-line prefer-destructuring
                this.addDialog.formModel.productDescList[index] = this.addDialog.formModel.productDescList.splice(
                    index - 1,
                    1,
                    this.addDialog.formModel.productDescList[index],
                )[0];
            } else {
                this.addDialog.formModel.productDescList.push(this.addDialog.formModel.productDescList.shift());
            }
        },
        onDownItem(row, index) {
            if (index !== this.addDialog.formModel.productDescList.length - 1) {
                // eslint-disable-next-line prefer-destructuring
                this.addDialog.formModel.productDescList[index] = this.addDialog.formModel.productDescList.splice(
                    index + 1,
                    1,
                    this.addDialog.formModel.productDescList[index],
                )[0];
            } else {
                this.addDialog.formModel.productDescList.unshift(
                    this.addDialog.formModel.productDescList.splice(index, 1)[0],
                );
            }
        },
    },
};
</script>

<style lang="scss">
</style>
